import React,{useState,useCallback} from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
function Loaders() {
 


  return (
    <div className="">
   {//<div className="spinner">
   //         <img src="newlogo.png" className="containerLoad" style={{margin:"0px",padding:"0px"}}/>
   //       </div>
   }

      <div className="spinner">
        <img src=
"newlogo.png" className="blink"/> 
    </div>
    </div>
   
  );
}

export default Loaders;